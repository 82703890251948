import { DataLabelsOptions, PointLabelObject, Tooltip, TooltipFormatterContextObject } from 'highcharts';
import { currencyFormatter, pctFormatter } from './formatters';

export function commonToolTipFormatter(
  this: TooltipFormatterContextObject,
  tooltip: Tooltip
): false | string | Array<string | null | undefined> | null | undefined {
  return `<span style="color: #079ab2">${this.point.name}</span>: <b>${currencyFormatter.format(this.point.y!)}</b>`;
}

export function commonDataPointFormatter(
  this: PointLabelObject,
  options: DataLabelsOptions
): number | string | null | undefined {
  const total = this.point.series.data.reduce((pv, cv) => pv! + cv.y!, 0);
  const pct = (this.point.y! / total) * 100.0;

  return `<span class='pieLabelStyle'>${currencyFormatter.format(this.point.y!)}</span><br /><span class='pieLabelStyle'>(${pctFormatter.format(pct)}%)</span>`;
}

