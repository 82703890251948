import { inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { HttpHelperService } from '@services/http-helper/http-helper.service';
import { DashboardData } from '../models/DashboardData';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private apiUrl = `${environment.apiUrl}v1/DemoPharmacy/`;

  private http = inject(HttpHelperService);

  constructor() {}

  public RetrieveData() {
    return this.http.get<DashboardData>(`${this.apiUrl}Dashboard/28`);
  }
}
