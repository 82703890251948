import { Routes } from '@angular/router';

//import { MsalGuard } from '@azure/msal-angular';

import { environment } from '@env/environment';

import { HomePageComponent } from './components/home-page/home-page.component';
//import { LoginFailedComponent } from './components/login-failed/login-failed.component';
//import { LogoutUserComponent } from './components/logout-user/logout-user.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GlobalTrackingComponent } from './components/global-tracking/global-tracking.component';

//import { UserProfileComponent } from './components/user-profile/user-profile.component';

/**
 * List of routes to use in the application. Default to the General Contractor component for now.
 */
export const routes: Routes = [
  {
    path: 'pharmacy',
    loadComponent: () =>
      import('./components/pharmacy-overview/pharmacy-overview.component').then((m) => m.PharmacyOverviewComponent),
    title: 'Pharmacy Overview'
  },
  {
    path: 'pharmacy2',
    loadComponent: () =>
      import('./components/pharmacy-overview-v2/pharmacy-overview.component').then((m) => m.PharmacyOverview2Component),
    title: 'Pharmacy Overview'
  },
  {
    path: 'pharmacy3',
    loadComponent: () =>
      import('./components/pharmacy-overview-v3/pharmacy-overview.component').then((m) => m.PharmacyOverview3Component),
    title: 'Pharmacy Overview'
  },
  {
    path: 'dataeval',
    loadComponent: () =>
      import('./components/data-eval-viewer/data-eval-viewer.component').then((m) => m.DataEvalViewerComponent),
    title: 'Vendor Data Evaluation'
  },
  //{ path: 'profile', component: UserProfileComponent, canActivate: [MsalGuard] },
  //{ path: 'login-failed', component: LoginFailedComponent },
  //{ path: 'logout', component: LogoutUserComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'dashboard', component: DashboardComponent, title: 'Global Dashboard' },
  { path: 'global-tracking', component: GlobalTrackingComponent, title: 'Global Visibility' },
  {
    path: 'facility-tracking',
    loadComponent: () =>
      import('./components/demo-facility-tracking/demo-facility-tracking.component').then(
        (m) => m.DemoFacilityTrackingComponent
      ),
    title: 'Facility Visibility'
  },
  {
    path: 'destroyed-drug-tracking',
    loadComponent: () =>
      import('./components/demo-destroyed-drugs/demo-destroyed-drugs.component').then(
        (m) => m.DemoDestroyedDrugsComponent
      ),
    title: 'Drugs Destroyed'
  },
  {
    path: 'dispensed-drug-tracking',
    loadComponent: () =>
      import('./components/demo-dispensed-drugs/demo-dispensed-drugs.component').then(
        (m) => m.DemoDispensedDrugsComponent
      ),
    title: 'Drugs Dispensed'
  },
  { path: '', redirectTo: `${environment.defaultPath}`, pathMatch: 'full' },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'Page Not Found'
  }
];
