import { ChangeDetectionStrategy, Component, inject, OnInit, signal, ViewChild } from '@angular/core';

import { CardModule } from 'primeng/card';
import { ChartDrillDownPieComponent } from '../charts/chart-drill-down-pie/chart-drill-down-pie.component';
import { DashboardService } from './services/dashboard.service';
import { DashboardData } from './models/DashboardData';
import { ChartThreeLevelDrillDownComponent } from '../charts/chart-three-level-drill-down/chart-three-level-drill-down.component';
import { AvatarModule } from 'primeng/avatar';
import { TagModule } from 'primeng/tag';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { ExpirationRiskColorMap, SavingsColorMap2 } from 'app/constants/chartColorMap';
import { ActualSavingsYtdStackedBarComponent } from '../charts/actual-savings-ytd-stacked-bar/actual-savings-ytd-stacked-bar.component';
import { ActualSavingsYtdSimplePieComponent } from '../charts/actual-savings-ytd-simple-pie/actual-savings-ytd-simple-pie.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    ActualSavingsYtdSimplePieComponent,
    ActualSavingsYtdStackedBarComponent,
    CardModule,
    ChartDrillDownPieComponent,
    ChartThreeLevelDrillDownComponent,
    AvatarModule,
    TagModule,
    DividerModule,
    ButtonModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {
  private dataService = inject(DashboardService);

  dashboardData = signal<DashboardData>({
    riskData: [],
    currentOnHandInventory: 0,
    averageInventoryTurnsYtd: 0,
    currentSavingsPotential: [],
    expirationLossYtd: [],
    onHandInventoryMOM: 0,
    inventoryTurnMOM: 0,
    actualSavingsYtd: []
  });

  ExpirationRiskColorMap = ExpirationRiskColorMap;

  SavingsColorMap2 = SavingsColorMap2;

  @ViewChild(ChartThreeLevelDrillDownComponent) currentExpirationRiskChart!: ChartThreeLevelDrillDownComponent;

  @ViewChild(ChartDrillDownPieComponent) expirationLossYtd!: ChartDrillDownPieComponent;

  @ViewChild(ActualSavingsYtdStackedBarComponent) actualSavingsYtdBarChart!: ActualSavingsYtdStackedBarComponent;

  @ViewChild(ActualSavingsYtdSimplePieComponent) actualSavingsYtdPieChart!: ActualSavingsYtdSimplePieComponent;

  constructor() {}

  ngOnInit(): void {
    console.log('DashboardComponent initialized');

    this.dataService.RetrieveData().subscribe({
      next: (data) => {
        this.dashboardData.set(data);
      },
      error: () => {
        alert('oops');
      }
    });
  }

  expandCurrentExpirationChart() {
    this.currentExpirationRiskChart.makeFullScreen();
  }

  expandExpirationLossYTD() {
    this.expirationLossYtd.makeFullScreen();
  }

  expandActualSavingsYTD() {
    //this.actualSavingsYtdChart.makeFullScreen();
    this.actualSavingsYtdPieChart.makeFullScreen();
  }
}

