@let pageData = this.dashboardData();

<div class="grid">
  <!-- <div class="col-12">
    <h3>Drug Inventory Metrics</h3>
  </div> -->
  <div class="col-12 flex flex-row gap-3">
    <div class="card flex flex-column flex-grow-1">
      <div class="card-header">
        <div class="flex flex-row align-items-center">
          <div class="p-2 surface-200 mr-2 border-round"><i class="fa-regular fa-shelves"></i></div>
          <span class="font-medium">On-Hand Inventory</span>
        </div>
      </div>
      <div class="card-body flex flex-grow-1">
        <div class="flex-1 flex flex-row align-items-center">
          <div class="flex-1 flex flex-column text-center">
            <span class="font-medium text-500 text-base">Current</span>
            <h2>$ {{ pageData.currentOnHandInventory }} BN</h2>
          </div>
          <p-divider class="flex-1" layout="vertical" />
          <div class="flex-1 flex flex-column text-center">
            <span class="font-medium text-500 text-base">MoM</span>
            <h2>
              {{ pageData.onHandInventoryMOM }}%​
              <i class="fa-duotone fa-solid fa-down-long text-green-500"></i>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="card flex flex-column flex-grow-1">
      <div class="card-header">
        <div class="flex flex-row align-items-center">
          <div class="p-2 surface-200 mr-2 border-round"><i class="fa-regular fa-box-dollar"></i></div>
          <span class="font-medium">Inventory Turns</span>
        </div>
      </div>
      <div class="card-body flex flex-grow-1">
        <div class="flex-1 flex flex-row align-items-center">
          <div class="flex-1 flex flex-column text-center">
            <span class="font-medium text-500 text-base">YTD</span>
            <h2>{{ pageData.averageInventoryTurnsYtd }}</h2>
          </div>
          <p-divider class="flex-1" layout="vertical" />
          <div class="flex-1 flex flex-column text-center">
            <span class="font-medium text-500 text-base">MoM</span>
            <h2>{{ pageData.inventoryTurnMOM }}% <i class="fa-duotone fa-solid fa-up-long text-green-500"></i></h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 xl:col-4">
    <div class="card">
      <div class="card-header">
        <div class="flex flex-row align-items-center">
          <div class="p-2 surface-200 mr-2 border-round"><i class="fa-regular fa-hexagon-exclamation"></i></div>
          <span class="font-medium">Current Expiration Risk</span>
          <p-button
            class="ml-auto"
            [text]="true"
            icon="fa-solid fa-expand"
            (onClick)="expandCurrentExpirationChart()"></p-button>
        </div>
      </div>
      <div class="card-body">
        <app-chart-three-level-drill-down
          class="block w-full h-full"
          [rawData]="pageData.riskData"
          [colorMap]="ExpirationRiskColorMap"></app-chart-three-level-drill-down>
      </div>
    </div>
  </div>
  <div class="col-12 xl:col-4">
    <div class="card">
      <div class="card-header">
        <div class="flex flex-row align-items-center">
          <div class="p-2 surface-200 mr-2 border-round"><i class="fa-regular fa-chart-line-down"></i></div>
          <span class="font-medium">Expiration YTD Loss</span>
          <p-button
            class="ml-auto"
            [text]="true"
            icon="fa-solid fa-expand"
            (onClick)="expandExpirationLossYTD()"></p-button>
        </div>
      </div>
      <div class="card-body">
        <app-chart-drill-down-pie
          class="w-full h-full"
          [rawData]="pageData.expirationLossYtd"
          [colorMap]="ExpirationRiskColorMap"></app-chart-drill-down-pie>
      </div>
    </div>
  </div>
  <div class="col-12 xl:col-4">
    <div class="card">
      <div class="card-header">
        <div class="flex flex-row align-items-center">
          <div class="p-2 surface-200 mr-2 border-round"><i class="fa-regular fa-piggy-bank"></i></div>
          <span class="font-medium">Actual Savings YTD</span>
          <p-button
            class="ml-auto"
            [text]="true"
            icon="fa-solid fa-expand"
            (onClick)="expandActualSavingsYTD()"></p-button>
        </div>
      </div>
      <div class="card-body">
        <app-actual-savings-ytd-simple-pie
          class="w-full h-full"
          [rawData]="pageData.actualSavingsYtd"
          [colorMap]="ExpirationRiskColorMap"></app-actual-savings-ytd-simple-pie>
      </div>
    </div>
  </div>
</div>

<!-- <div class="grid">
  <div class="col-12">
    <h3>Drug Inventory Metrics</h3>
  </div>
  <div class="col-3 flex flex-column gap-2">
    <div class="card">
      <div class="card-header">
        <p-avatar icon="fa-duotone fa-solid fa-home fa-sm" styleClass="mr-2" /> Current On-Hand Inventory
      </div>
      <div class="card-body">
        <h2><span class="text-500">$</span>{{ pageData.currentOnHandInventory }} MM</h2>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <p-avatar icon="fa-duotone fa-solid fa-home" styleClass="mr-2" /> MoM On-Hand Inventory ∆
      </div>
      <div class="card-body">
        <h2>
          {{ pageData.onHandInventoryMOM }}%​
          <i class="fa-duotone fa-solid fa-down-long text-green-500"></i>
        </h2>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <p-avatar icon="fa-duotone fa-solid fa-home" styleClass="mr-2" /> YTD Inventory Turns
      </div>
      <div class="card-body">
        <h2>{{ pageData.averageInventoryTurnsYtd }}</h2>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <p-avatar icon="fa-duotone fa-solid fa-home" styleClass="mr-2" /> MoM Inventory Turn ∆
      </div>
      <div class="card-body">
        <h2>{{ pageData.inventoryTurnMOM }}% <i class="fa-duotone fa-solid fa-up-long text-green-500"></i></h2>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <p-avatar icon="fa-duotone fa-solid fa-home" styleClass="mr-2" /> Current Expiration Risk
          </div>
          <div class="card-body flex">
            <app-chart-three-level-drill-down
              class="m-auto"
              [rawData]="pageData.riskData"
              [title]="''"></app-chart-three-level-drill-down>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            <p-avatar icon="fa-duotone fa-solid fa-home" styleClass="mr-2" /> Expiration YTD Loss
          </div>
          <div class="card-body">
            <app-chart-drill-down-pie [rawData]="pageData.expirationLossYtd" [title]="''"></app-chart-drill-down-pie>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            <p-avatar icon="fa-duotone fa-solid fa-home" styleClass="mr-2" /> Actual Savings YTD
          </div>
          <div class="card-body">
            <app-chart-simple-pie [rawData]="pageData.actualSavingsYtd" [title]="''"></app-chart-simple-pie>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="grid">
  <p-card header="Current Drug Inventory Metrics" class="w-full">
    <div class="w-full grid gap-0 cardBackground">
      <div class="flex flex-column justify-content-center gap-5 align-items-center col">
        <div class="itemHeader">Current On-Hand Inventory</div>
        <div class="itemValue">${{ pageData.currentOnHandInventory }} MM</div>
      </div>

      <app-chart-three-level-drill-down
        [rawData]="pageData.riskData"
        [title]="'Current Expiration Risk'"></app-chart-three-level-drill-down>

      <div class="flex flex-column justify-content-center gap-5 align-items-center col">
        <div class="itemHeader">YTD Average Inventory Turns</div>
        <div class="itemValue">{{ pageData.averageInventoryTurnsYtd }}</div>
      </div>
    </div>
  </p-card>

  <p-card header="Drug Management Outcomes​" class="w-full">
    <div class="w-full grid gap-0 cardBackground">
      <app-chart-drill-down-pie
        [rawData]="pageData.expirationLossYtd"
        [title]="'Expiration YTD Loss'"></app-chart-drill-down-pie>

      <div class="flex flex-column justify-content-center gap-5 align-items-center col">
        <div class="itemHeader">MOM On-Hand Inventory ∆​</div>
        <div class="itemValue">
          {{ pageData.onHandInventoryMOM }}%​ <span style="color: green"><i class="fa-solid fa-down-long"></i></span>
        </div>
        <div class="itemHeader">MOM Inventory Turn ∆​</div>
        <div class="itemValue">
          {{ pageData.inventoryTurnMOM }}% <span style="color: green"><i class="fa-solid fa-up-long"></i></span>
        </div>
      </div>

      <app-chart-simple-pie [rawData]="pageData.actualSavingsYtd" [title]="'Actual Savings YTD'"></app-chart-simple-pie>
    </div>
  </p-card>
</div> -->

